@import 'ui/styles/utilities';

.container {
  position: sticky;
  top: var(--navbar-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.outer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
  overflow: auto;
  height: 100%;
  padding-right: calc(24rem + (var(--padding) * 0.666));
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vw;
  gap: calc(var(--padding) * 0.75);
  margin: 0 auto;
}

.preview {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all var(--speed) var(--ease);

  a {
    transition: all var(--speed) var(--ease);

    span {
      transition: all calc(var(--speed) / 2) var(--ease);
      transition-property: color, font-size;
    }
  }
}

.preview.v1 {
  background: #fff;
  border-radius: 0.3rem;
}

.item {
  position: relative;
  flex-shrink: 0;
}

.select {
  position: absolute;
  bottom: 100%;
  left: 0;
  font-size: 0.625rem;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 0.4em;
    height: 0.4em;
    border-bottom: 1px solid var(--dark40);
    border-right: 1px solid var(--dark40);
    transform: translate3d(0, -150%, 0) rotate(45deg) scale(1.15);
    pointer-events: none;
  }

  select {
    appearance: none;
    color: var(--dark40);
    background: transparent;
    border: 0;
    font-family: var(--font-mono);
    font-feature-settings: var(--font-mono-settings);
    font-weight: 500;
    margin-bottom: 0.5em;
    padding-right: 1em;
    font-size: inherit;
    cursor: pointer;
  }
}

.add {
  line-height: 1em;
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.4em;
  border: 1.5px solid var(--dark80);
  transform: translate3d(0, -50%, 0) rotate(0.01deg);
  overflow: hidden;
  cursor: pointer;
  transition: opacity var(--speed) var(--ease);
  opacity: 0.2;
  margin-right: calc((1.6em + (var(--padding) * 0.75)) * -1);
  flex-shrink: 0;

  &:hover {
    opacity: 0.3;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    transform: translate3d(-50%, -50%, 0) rotate(0deg) scale(0.75);
    transition: transform var(--speed) var(--ease);
    color: var(--dark);

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(0.1deg);
      width: 0.1rem;
      height: 90%;
      background: currentColor;
      border-radius: 5em;
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }
}

.delete {
  position: absolute;
  bottom: 100%;
  right: 0;
  color: var(--dark30);
  font-size: 1.2em;
  opacity: 0;
  transition: opacity var(--speed) var(--ease);
  cursor: pointer;
}

.item:hover .delete {
  opacity: 1;
}

.preview-wrapper {
  font-size: 1em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @at-root .preview.v1.landscape & {
    font-size: 1.2em;
  }

  @at-root .preview.v1.square & {
    font-size: 1em;
  }

  @at-root .preview.v1.portrait.preview-portrait & {
    font-size: 1.3em;
  }

  @at-root .preview.v1.landscape.preview-portrait & {
    font-size: 1em;
  }

  @at-root .preview.v1.landscape.preview-square & {
    font-size: 1em;
  }
}

.show {
  position: relative;

  &-cover {
    position: relative;
    pointer-events: none;
    transition: opacity calc(var(--speed) / 2) var(--ease);
    z-index: 3;
  }

  &-crop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 0.9em;
    display: none;
  }

  // &:hover &-cover {
  //   opacity: 0.5;
  // }
}
