@import 'ui/styles/utilities';

.container {
  position: relative;
  display: flex;
  align-items: center;
}

.preview {
  position: relative;
  width: 4rem;
  min-height: 4rem;
  background: var(--dark05);
  border-radius: var(--border-radius);
  overflow: hidden;
  mask-image: radial-gradient(white, black);
  transform: translate3d(0, 0, 0) rotate(0.1deg);

  &-image {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.replace {
  padding: 0 1rem;
}

.drag {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: var(--white);
  opacity: 0;
  transition: opacity var(--speed) var(--ease);

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    width: 100%;
    border-radius: var(--border-radius);
    border: 1.5px dashed var(--dark05);
    color: var(--dark40);

    svg {
      width: 1.5rem;
      margin-right: 0.5em;
      opacity: 0.7;

      path {
        fill: currentColor;
      }
    }

    &:after {
      content: attr(data-text);
    }
  }
}

.dragging .drag {
  opacity: 1;
}

// actions
.actions {
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4em;
  transition: transform var(--speed) var(--ease);
  transform: translate3d(0, 1em, 0);

  @at-root .file & {
    transform: translate3d(0, 0, 0);
  }

  &-action {
    position: relative;
    padding: 2px 0.75em 2px 2px;
    border-radius: var(--border-radius);
    color: var(--dark50);
    display: flex;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      background: currentColor;
      opacity: 0.1;
      transition: opacity var(--speed) var(--ease);
    }

    svg {
      display: block;
      width: 1.6em;
      margin-right: 0.15em;

      path {
        fill: currentColor;
      }
    }

    &:hover:before {
      opacity: 0.2;
    }

    &--danger {
      color: var(--red);
      opacity: 0;

      @at-root .file & {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  opacity: 0;
  transition: opacity var(--speed) var(--ease);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark05);
  }
}

.loading .loader {
  opacity: 1;
}

// cover
.cover .preview {
  height: 5.25rem;
}

.full {
  width: 100%;
  height: 100%;
  cursor: pointer;

  .preview {
    width: 100%;
    height: 100%;
  }

  &:not(.file):not(.loading) .drag {
    opacity: 1;
  }
}

// TODO: Resinstate crop
// .crop {
//   .preview {
//     opacity: 0;
//   }
// }

.small {
  // background: var(--dark05);
  border-radius: var(--border-radius);
  // margin-top: -0.5em;
  cursor: pointer;

  .preview {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 0;
    transition: opacity var(--speed) var(--ease);

    @at-root .file#{&} {
      background: var(--background, #070707);
    }

    img {
      object-fit: cover;
      opacity: 0.5;
    }
  }

  &:not(.file):not(.loading) .drag {
    opacity: 1;
  }

  .drag {
    font-size: 0.9em;
    background: none;
    margin: 0.25em;
    width: calc(100% - 0.5em);
    height: calc(100% - 0.5em);

    &-text {
      border-radius: calc(var(--border-radius) / 1.5);
      border-color: transparent;
      transition: border-color var(--speed) var(--ease);
      background: none;
      opacity: 0.8;
    }

    svg {
      margin-right: 0.25em;
    }
  }

  @at-root.dragging .drag {
    &-text {
      border-color: var(--dark05);
    }
  }

  @at-root.file.dragging .preview {
    opacity: 0;
  }

  @at-root.file.dragging .drag {
    margin: 0;
    width: calc(100%);
    height: calc(100%);
  }

  .actions {
    transform: translate3d(0, 0, 0);
    flex-direction: row;
    margin-left: auto;
    gap: 0;
    padding: 0.3em;
    opacity: 0;
    pointer-events: none;

    &-action {
      padding-right: 0;
      color: var(--white);
      margin-left: -0.2em;

      &:before {
        opacity: 0;
      }

      &--danger {
        color: var(--white);
      }

      em {
        display: none;
      }
    }
  }
}

.small.file .actions {
  opacity: 1;
  pointer-events: all;
}
